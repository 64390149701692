.dot {
  height: 12px;
  width: 12px;
  background-color: #F0F0F0;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.dot-selected {
  background-color: #636363;
}

/* Shapescale title */

.title {
  font-size: 30px;
  color: #000000;
  text-align: center;
  font-family: "Uniform";
  padding-top: 20px;
  padding-bottom: 20px;
}

#top-section {
  height: 60%;
  max-height: 60%;
}

.intro-image {
  text-align: center;
  margin: auto;
  display: block;
  width: 15%;
}


@media only screen and (max-width: 600px) {
  .intro-image {
    width: 60%;
  }

  img#image-0 {
    width: 100% !important;
  }
}

.intro-text {
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  margin: auto;
  display: block;
  font-size: 25px;
  font-family: "Uniform-Medium";
}

#ciclesDiv {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

#bottom-section {
  padding-top: 20px;
  background: linear-gradient(#F1F1F1, #E4E4E4);
  height: 40%;
  display: block;
}

#bottom-section-1 {
  height: 33%;
  padding-top: 10px;
}

#bottom-section-2 {
  height: 33%;
  padding-top: 20px;
  width: 80%;
  margin: auto;
  text-align: center;
}

#bottom-section-3 {
  height: 33%;
  padding-top: 20px;
  padding-bottom: 80px;
  width: 80%;
  margin: auto;
  text-align: center;
}

#enter-email {
  font-family: "Uniform";
  font-size: 20px;
  text-align: center;
  padding-bottom: 10px;
}

.input {
  width: 100%;
}

#text-input {
  text-align: left;
  align-content: center;
  align-self: center;
  margin: auto;
  display: block;
  border-radius: 26px;
  height: 52px;
  font-size: 16px;
}

#text-input::placeholder {
  font-family: "Uniform-Medium";
  color: #A0A0A0;
  text-align: left;
}