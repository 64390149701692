#guide-div-outer {
    background-color: #EBEBEB;
}

#guide-div-inner {
    width: 90%;
    text-align: center;
    margin: auto;
}

#title {
    font-family: "Uniform-Medium";
    font-size: 31px;
    text-align: center;
    padding-top: 20px;
}

#sub-title {
    font-family: "Uniform";
    font-size: 16px;
    text-align: center;
    color: #4D4D4D;
    padding-top: 20px;
}

.section-div {
    padding-top: 20px;
}

.section-title {
    font-family: "Uniform-Medium";
    font-size: 31px;
    text-align: center;
    color: #4D4D4D;
    padding-top: 20px;
}

.section-text {
    font-family: "Uniform";
    font-size: 16px;
    text-align: center;
    color: #4D4D4D;
    padding-top: 20px;
}

.section-image {
    text-align: center;
}

.image {
    width: 20%;
}

@media only screen and (max-width: 600px) {
    .image {
      width: 50% !important;
    }
  }

#button {
    padding-top: 20px;
}

#link-div {
    padding-top: 20px;
    padding-bottom: 20px;
}

#link {
    font-family: "Uniform-Medium";
    color: #4D4D4D;
    font-size: 16px;
    text-decoration: underline;
}