model-viewer {
    width: 100%;
    height: 500px;
    margin: auto;
    padding-top: 20px;
}

#top-section {
    width: 80%;
    text-align: center;
    margin: auto;
    padding-bottom: 30px;
}

#scan-title {
    padding-top: 20px;
    font-family: "Uniform";
    font-size: 31px;
    text-align: center;
}

#scan-text {
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: "Uniform";
    font-size: 18px;
    text-align: center;
    color: #4D4D4D;
}

#scan-logo-in-button {
    height: 20px;
    vertical-align: middle;
    padding-right: 0px;
}