.default-button {
  height: 52px;
  text-align: center;
  width: 100%;
  display: block;
  margin: auto;
  border-radius: 26px;
  background-color: #4D4D4D;
  font-family: "Uniform-Medium";
  font-size: 20px;
  text-align: center;
  color: #FFFFFF;
}
