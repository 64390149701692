#qrcode-div-outer {
    background-color: #EBEBEB;
}

#qrcode-div-inner {
    width: 20%;
    text-align: center;
    margin: auto;
}

@media only screen and (max-width: 600px) {
    #qrcode-div-inner {
        width: 90% !important;
    }
  }

#qrcode-image {
    width: 100%;
    padding-top: 50px;
}

#text {
    padding-top: 20px;
    font-family: "Uniform-Medium";
    font-size: 22px;
    text-align: center;
}

#image {
    padding-top: 20px;
}

#image img { 
    width: 50%;
}

#button {
    padding-top: 40px;
}