#footer-outer {
    background-color: #252525;
}

#shapescale-logo {
 text-align: center;
 padding-top: 40px;
}

#social-links-div {
    padding-top: 20px;
    margin: auto;
    text-align: center;
}

.social-links-divs {
    display: inline-flex;
    padding-top: 20px;
    width: 33%;
    text-align: center;
    margin: auto;
}

.social-links-a-tags {
    text-align: center;
    margin: auto;
}

#instagram {
    height: 25px;
    width: 25px;
}

#x {
    height: 22px;
    width: 27px;
}

#facebook {
    height: 21px;
    width: 11px;
}

#shapescale {
    height: 44px;
    width: 43px;
}

#footer-text-links-div {
    padding-top: 20px;
}

.footer-text-links-div {
    text-align: center;
    padding-top: 20px;
}

.footer-text-links-a {
    font-family: "Arial";
    font-size: 12px;
    color: #FFFFFF;
}

#address-div {
    padding-top: 20px;
    padding-bottom: 30px;
    font-family: "Uniform";
    font-size: 10px;
    text-align: center;
    color: #F6F6F6;
}