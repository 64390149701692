#processing-div-outer {
    background-color: #EBEBEB;
}

#processing-div-inner {
    width: 40%;
    text-align: center;
    margin: auto;
    padding-bottom: 50px;
}

@media only screen and (max-width: 600px) {
    #processing-div-inner {
        width: 90% !important;
    }
}

.processing-image {
    width: 20%;
    padding-top: 30px;
}

@media only screen and (max-width: 600px) {
    .processing-image {
        width: 60% !important;
    }
}

.processing-titles {
    font-family: "Uniform-Medium";
    font-size: 31px;
    text-align: center;
    padding-top: 20px;
}

.processing-text {
    padding-top: 20px;
    font-family: "Uniform";
    font-size: 20px;
    text-align: center;
}